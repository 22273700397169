
































































































import { Component, Vue, Watch } from 'vue-property-decorator'
// @ts-ignore
import { Route } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'
import { Form as ElForm, Input } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import { isValidUsername, isPhone } from '@/utils/validate'
import { setToken } from '@/utils/cookies'
import { login, sendPhoneCode, hasPhoneCodeValid } from '@/api/users'
import md5 from 'js-md5'
import { CommonMudule } from '@/store/modules/common'

interface ErrObj {
   9110: Function
    9001: Function
    9101: Function
    9102: Function
    9109: Function
}

@Component({
  name: 'Login'
})
export default class extends Vue {
  clickAble = true
  timeNum = 60
  timer: null | NodeJS.Timer = null
  private passwordType = 'password'
  private loading = false
  private showDialog = false
  private redirect?: string
  private otherQuery: Dictionary<string> = {}
  private hasValid = false
  private loginForm = {
    credential: '',
    password: '',
    tenantId: '',
    code: ''
  }

  private validateUsername = (rule: any, value: string, callback: Function) => {
    if (!isPhone(value)) {
      callback(new Error('Please enter the correct user name'))
    } else {
      callback()
    }
  }

  private validatePassword = (rule: any, value: string, callback: Function) => {
    if (value.length < 4) {
      callback(new Error('请输入密码'))
    } else {
      callback()
    }
  }

  private errObj = {
    9110: () => { this.$message.error('请输入有效的手机号') },
    9001: () => { this.$message.error('请输入正确的手机号/验证码') },
    9101: () => { this.$message.error('短信服务中断，请联系您的客服') },
    9102: () => { this.$message.error('手机号有误，请重新输入') },
    9109: () => { this.$message.error('请于一分钟后重新获取') },
  }

  private async getCode() {
    if (!this.loginForm.credential) {
      this.$message.error('请输入手机号')
      return
    }

    if (!isPhone(this.loginForm.credential)) {
      this.$message.error('请输入正确的手机号')
      return
    }
    this.clickAble = false
    this.timeNum = 60
    this.beganTime()
    const res = await sendPhoneCode(this.loginForm.credential)
    if (res.statusCode === 0 && res.data) {
      console.log('res11', res)
    } else {
      const statusCode: keyof ErrObj = res.statusCode
      if (Object.keys(this.errObj).includes(String(statusCode))) {
        this.errObj[statusCode]()
      } else {
        this.$message.error(res.errorMsg)
      }
    }
  }

  private beganTime() {
    this.timer = setInterval(() => {
      this.timeNum--
      if (this.timeNum === 0) {
        clearInterval(this.timer as NodeJS.Timer)
        this.clickAble = true
        this.timeNum = 60
      }
    }, 1000)
  }

  private loginRules = {
    credential: [
      { validator: this.validateUsername, trigger: 'blur', message: '请输入正确的手机号' },
      { validator: this.validateUsername, trigger: 'change', message: '请输入正确的手机号' }
    ],
    password: [
      { validator: this.validatePassword, trigger: 'blur' },
      { validator: this.validatePassword, trigger: 'change' }
    ],
    code: [
      { required: true, trigger: 'blur', message: '请输入手机验证码' },
      { required: true, trigger: 'change', message: '请输入手机验证码' }
    ]
  }

  get logoUrl() {
    return CommonMudule.imgSource.logo[0].url
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(route: Route) {
    const query = route.query as Dictionary<string>
    if (query) {
      this.redirect = query.redirect
      this.otherQuery = this.getOtherQuery(query)
    }
  }

  mounted() {
    this.onHasPhoneCodeValid()
    if (this.loginForm.credential === '') {
      (this.$refs.credential as Input).focus()
    } else if (this.loginForm.password === '') {
      (this.$refs.password as Input).focus()
    }
  }

  private showPwd() {
    if (this.passwordType === 'password') {
      this.passwordType = ''
    } else {
      this.passwordType = 'password'
    }
    this.$nextTick(() => {
      (this.$refs.password as Input).focus()
    })
  }

  private handleLogin() {
    (this.$refs.loginForm as ElForm).validate(async(valid: boolean) => {
      if (valid) {
        this.loading = true
        const form = {
          credential: this.loginForm.credential,
          password: md5(this.loginForm.password.trim()),
          code: this.loginForm.code,
          tenantId: ''
        }
        const res = await login(form)
        const { data, statusCode } = res
        if (res.statusCode === 0 && res.data) {
          setToken(data.token)
          UserModule.SET_TOKEN(data.token)
          UserModule.SET_USERDATA(data)
          this.$message({
            type: 'success',
            message: '登录成功'
          })
          this.$router.push({
            path: '/userSelect',
            query: this.otherQuery
          })
        } else {
          this.$message.error(res.errorMsg)
        }
        this.loading = false
      } else {
        this.loading = false
        return false
      }
    })
  }

  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur]
      }
      return acc
    }, {} as Dictionary<string>)
  }

  private async onHasPhoneCodeValid() {
    const res = await hasPhoneCodeValid()
    if (res.statusCode === 0 && res.data) {
      const { sms } = res.data
      if (sms) {
        this.hasValid = true
      }
    } else {
      this.$message.error(res.errorMsg)
    }
    // if
    // console.log('hasPhoneCodeValid', res)
  }
}
